import copyrightStyles from '../../styles/footer/Copyright.module.scss';
import Link from 'next/link';
import DefaultInfo from './DefaultInfo';

let getYear = () => {
  let currentYear = new Date().getFullYear();
  return currentYear;
};

const Copyright = () => {
  return (
    <div className={copyrightStyles.copyright}>
      <p>
        Copyright {getYear()} More2Child Care. All Rights Reserved. <Link href="/cookies">Cookies</Link>.{' '}
        <a href="https://cdn.sanity.io/files/9g8ar69c/production/771ac59f22ec13b6717c0876f62be64483a9eecc.pdf?dl=Privacy-Policy.pdf" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
        <span style={{ fontSize: '0.7em', marginTop: '0.1em', paddingTop: '0.5em', lineHeight: '1.4', opacity: '0.4', display: 'block' }}>
          More2Childcare CIC, Registered Office: Forum at Greenwich, Trafalgar Road, London SE10 9EQ. Registered in England. Company Number 09651188
          <br />
          More2Riverside Ltd, Registered Office: 2 River Gardens Walk, Banning Street, Greenwich SE10 0FZ. Registered in England. Company Number 11345550
        </span>
      </p>
    </div>
  );
};

export default Copyright;
